import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { StatsService } from "../stats.service";
import { StateManager } from "./stateManager";
import { ActivatedRoute, Router } from "@angular/router";
import {
  IndicatorState,
  StatsIndicatorLocationPresentationView,
} from "./indicator.state";
import { ChartViewerFilterComponent } from "../uilib/chart-viewer-filter/chart-viewer-filter.component";
import { MapViewComponent } from "./indicatorViews/map-view/map-view.component";
import { SnapshotViewComponent } from "./indicatorViews/snapshot-view/snapshot-view.component";
import { TrendViewComponent } from "./indicatorViews/trend-view/trend-view.component";
import { TableViewComponent } from "./indicatorViews/table-view/table-view.component";
import { Location } from "@angular/common";
import { Util } from "../shared/util";
import { SortableCheckboxMenuComponent } from "../uilib/sortable-checkbox-menu/sortable-checkbox-menu.component";
import { ToolbarButtonComponent } from "../uilib/toolbar-button/toolbar-button.component";
import { HybridDropdownComponent } from "../uilib/hybrid-dropdown/hybrid-dropdown.component";
import {
  VisualisationDownloadFormat,
  Locations,
  ViewType,
} from "../../app/indicator/constraints";
import { BreadcrumbService } from "../app-breadcrumb/breadcrumb.service";
import { BreadcrumbConfig } from "../app-breadcrumb/breadcrumb.config";
import { DialogComponent } from "../uilib/dialog/dialog.component";
import { ContentService } from "../content.service";
import { Observable } from "rxjs";
import { Accordion } from "../uilib/accordion/accordion.model";
import { ContinuumGroup } from "../uilib/continuum-accordion/continuum.model";
import { Section } from "../myreports/report.model";
import { ReportService } from "../myreports/report.service";
import { IndicatorTableService } from "./indicator-table.service";
import { CsvService } from "../shared/csv.service";
import { map } from "rxjs/operators";
import { GoogleAnalyticsService } from "../services/google-analytics.service";
import { AppInsightsService } from "../services/appinsights.service";
import { SiteService } from "../site.service";
import { RedirectService } from "../components/redirect/redirect.service";
import { MyReportsIndicatorItemSelectionDialogComponent } from "../myreports/myreportsindicatoritemselectiondialog/myreportsindicatoritemselectiondialog.component";
import { AppConfig } from "../app.config";
import { MetadataService } from "../metadata.service";

@Component({
  selector: "app-indicator",
  templateUrl: "./indicator.component.html",
  styleUrls: ["./indicator.component.css"],
})
export class IndicatorComponent implements OnInit {
  citationData = {
    source:
      "Centre for Epidemiology and Evidence.  HealthStats NSW. Sydney:  NSW Ministry of Health",
    url: window.location.href,
    date: new Date(),
  };

  @ViewChild(HybridDropdownComponent, { static: false })
  private newMeasureMenu: HybridDropdownComponent;

  @ViewChild(SortableCheckboxMenuComponent, { static: false })
  private compareMenu: SortableCheckboxMenuComponent;

  @ViewChild(ChartViewerFilterComponent, { static: false })
  private chartViewerFilter: ChartViewerFilterComponent;

  @ViewChild(TrendViewComponent, { static: false })
  private trendViewRef: TrendViewComponent;

  @ViewChild(SnapshotViewComponent, { static: false })
  private snapShotViewRef: SnapshotViewComponent;

  @ViewChild(MapViewComponent, { static: false })
  private mapViewRef: MapViewComponent;

  @ViewChild(TableViewComponent, { static: false })
  private tableViewRef: TableViewComponent;

  @ViewChild("citationDialog", { static: false })
  citationDialog: DialogComponent;
  @ViewChild("reference", { static: false }) reference: ElementRef;
  @ViewChild("filterToggleView", { static: false })
  filterToggleView: ToolbarButtonComponent;
  @ViewChild("trendView", { static: false }) trendView: ToolbarButtonComponent;
  @ViewChild("snapshotView", { static: false })
  snapshotView: ToolbarButtonComponent;
  @ViewChild("mapView", { static: false }) mapView: ToolbarButtonComponent;
  @ViewChild("tableView", { static: false }) tableView: ToolbarButtonComponent;
  @ViewChild("confidenceIntervals", { static: false })
  confidenceIntervals: ToolbarButtonComponent;
  @ViewChild("myReportsIndicatorItemSelectionDialog", { static: false })
  _myReportsIndicatorItemSelectionDialog: MyReportsIndicatorItemSelectionDialogComponent;

  @Output() stateUpdated: EventEmitter<IndicatorState> = new EventEmitter();

  source: string;
  releaseDate?: string;
  activeView: string;
  stateManager: StateManager;
  stateLocalCopy: IndicatorState;
  Locations = Locations;
  accordions: Accordion[];
  continuumAccordions$: Observable<ContinuumGroup[]>;
  indicatorName: string;
  reportSection: Section;
  sections: Section[] = [];
  newReport: boolean;
  displayDownloadBubble = false;
  displayAddReportBubble = false;
  confidenceIntervalViewEnabled = false;
  viewType = ViewType;
  visualisationDownloadFormat = VisualisationDownloadFormat;
  myReportsEnabled: boolean;

  constructor(
    private statsService: StatsService,
    private contentService: ContentService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private breadcrumbService: BreadcrumbService,
    private reportService: ReportService,
    private indicatorTableService: IndicatorTableService,
    private csvService: CsvService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private appInsightsService: AppInsightsService,
    private _siteService: SiteService,
    private metadataService: MetadataService
  ) {
    this.stateManager = new StateManager(
      this.route,
      this.router,
      this.statsService,
      this.location
    );
   this.stateManager.onStateUpdated = (state) => this.onStateUpdated(state);
  }
  
  ngOnInit() {
    this.myReportsEnabled = AppConfig.settings.featureSettings.enableMyReport;
    this.route.queryParams.subscribe((params) => {
      const previousIndicatorName = this.indicatorName;
      this.stateManager.updateStateFromUrl(params);    
      this.indicatorName = params["name"];

      if (!this.accordions || previousIndicatorName !== this.indicatorName) {
        this.extractSources();
        this.statsService
        .getIndicatortTerms(this.indicatorName)
        .subscribe((data: any) => {
          if (this.metadataService) {
            this.metadataService.updateMetadata({
              title: data.indicator,
              description: data.indicator,
              keywords: [data.keys]
            });
          }
        });
      }
    });
  }

  extractSources() {
    this.contentService
      .getIndicatorText(this.indicatorName)
      .pipe(
        map((accordions) => {
          const sourceAccordion = accordions.find(
            (accordion) => accordion.title === "Sources (under visualisation)"
          );
          if (sourceAccordion) {
            accordions = Util.remove(accordions, sourceAccordion);
            this.source = "";
            sourceAccordion.children.forEach(
              (textSnippet) => (this.source += textSnippet.articleText)
            );
          }
          return accordions;
        })
      )
      .subscribe((accordions) => (this.accordions = accordions));
  }

  initBreadcrumb() {
    this.breadcrumbService.updateBreadCrumbs([
      BreadcrumbConfig.TOPICS,
      { name: this.stateLocalCopy.Meta.title, route: "/" },
    ]);
  }

  onStateUpdated(stateFromStateManager: IndicatorState) {
    this.stateLocalCopy = JSON.parse(JSON.stringify(stateFromStateManager));

    this.initBreadcrumb();
    this.activeView = this.stateLocalCopy.View;
    this.releaseDate = this.stateLocalCopy?.Meta ?.releaseDate;

    // TODO remove this statement below
    const measureItems = this.stateLocalCopy.Meta.measures.reduce(
      (accumulator, item) => {
        // TODO: this if condition is a temp cleanup for few items in list
        if (
          item.description.indexOf("fix") < 0 &&
          item.description.indexOf("Fix") < 0
        ) {
          accumulator.push(item);
        }
        return accumulator;
      },
      []
    );

    // If a confidence interval exists for an indicator measure,
    // enable the confidence interval view on the chart by default.
    if(this.showCiButton() && !this.confidenceIntervalViewEnabled){
      this.stateLocalCopy.Confidence = true;      
    }
    this.confidenceIntervalViewEnabled = false;
    this.setToolbarButtonsStatus(this.activeView);
    this.setToolbarButtonsDisabledStatus(this.stateLocalCopy);
    this.stateLocalCopy.Meta.measures = measureItems;
    this.newMeasureMenu.onStateUpdated(this.stateLocalCopy);
    this.compareMenu.onStateUpdated(this.stateLocalCopy);
    this.chartViewerFilter.onStateUpdated(this.stateLocalCopy);

    if (
      this.trendViewRef !== undefined &&
      this.activeView === this.viewType.Trend
    ) {
      this.trendViewRef.onStateUpdated(this.stateLocalCopy);
    }
    if (
      this.snapShotViewRef !== undefined &&
      this.activeView === this.viewType.BarHorizontal
    ) {
      this.snapShotViewRef.onStateUpdated(this.stateLocalCopy);
    }
    if (
      this.mapViewRef !== undefined &&
      this.activeView === this.viewType.Map
    ) {
      this.mapViewRef.onStateUpdated(this.stateLocalCopy);
    }
    if (
      this.tableViewRef !== undefined &&
      this.activeView === this.viewType.Table
    ) {
      this.tableViewRef.onStateUpdated(this.stateLocalCopy);
    }
  }

  onLocationSubdivisionChanged(id: string) {
    this.stateLocalCopy.Groups = [];
    this.stateLocalCopy.Filter = {};

    if (id !== Locations.NSW) {
      if (!this.stateLocalCopy.Compare.includes(id)) {
        this.stateLocalCopy.Compare.push(id);
      }
    }

    this.stateLocalCopy.Location = id;
    this.stateManager.updateStateFromIndicator(this.stateLocalCopy);
  }

  onSelectedLocationsChanged(selectedLocations: string[]) {
    if (selectedLocations.length === 0) {
      delete this.stateLocalCopy.Filter[this.stateLocalCopy.Location];
    } else {
      this.stateLocalCopy.Filter[this.stateLocalCopy.Location] =
        selectedLocations;
    }
    this.stateManager.updateStateFromIndicator(this.stateLocalCopy);
  }

  onMeasureClicked(measure: string[]) {
    this.stateLocalCopy.Measure = Util.copy(measure);
    this.stateManager.updateStateFromIndicator(this.stateLocalCopy);
  }

  onCompareClicked(compare: string[]) {
    this.stateLocalCopy.Compare = compare;
    this.stateManager.updateStateFromIndicator(this.stateLocalCopy);
  }

  onGroupSelected(groupId: string) {
    this.stateLocalCopy.Groups.push(groupId);
    if (!this.stateLocalCopy.Compare.includes(groupId)) {
      this.stateLocalCopy.Compare.push(groupId);
    }
    this.updateDefaultMeasure();
    this.stateManager.updateStateFromIndicator(this.stateLocalCopy);
  }

  onGroupRemoved(groupId: string) {
    const index = this.stateLocalCopy.Groups.indexOf(groupId);
    if (index > -1) {
      this.stateLocalCopy.Groups.splice(index, 1);
    }
    if (this.stateLocalCopy.Compare.includes(groupId)) {
      this.stateLocalCopy.Compare = Util.remove(
        this.stateLocalCopy.Compare,
        groupId
      );
    }
    this.updateDefaultMeasure();
    this.stateManager.updateStateFromIndicator(this.stateLocalCopy);
  }

  onGroupItemsChanged(change: any) {
    if (change.groupItems.length === 0) {
      delete this.stateLocalCopy.Filter[change.groupId];
    } else {
      this.stateLocalCopy.Filter[change.groupId] = change.groupItems;
    }
    this.stateManager.updateStateFromIndicator(this.stateLocalCopy);
  }

  onPeriodItemsChanged(change: any) {
    this.stateLocalCopy.Filter["Period"] = change;
    this.stateManager.updateStateFromIndicator(this.stateLocalCopy);
  }

  onFilteringClicked() {
    this.filterToggleView.Toggle();
  }

  onTrendViewClicked() {
    this.stateLocalCopy.View = this.activeView = ViewType.Trend;
    this.updateDefaultMeasure();
    this.stateManager.updateStateFromIndicator(this.stateLocalCopy);
    this.setToolbarButtonsStatus(this.activeView);
  }

  updateDefaultMeasure() {
    const groups = this.stateLocalCopy.Groups.filter(
      (item) => item !== "Period"
    );
    const location = this.stateLocalCopy.Meta.locations.find(
      (item) => item.name === this.stateLocalCopy.Location
    );

    if (location) {
      const presentation = location.presentations.find((item) => {
        return Util.arraysEqual(item.groups, groups);
      });
      if (presentation) {
        const view = presentation.views.find(
          (item) => item.name === this.stateLocalCopy.View
        );
        if (view) {
          this.stateLocalCopy.Measure = [view.defaultMeasure];
        }
      }
    }
  }

  onSnapshotClicked() {
    this.stateLocalCopy.View = this.activeView = ViewType.BarHorizontal;
    this.updateDefaultMeasure();
    this.stateManager.updateStateFromIndicator(this.stateLocalCopy);
    this.setToolbarButtonsStatus(this.activeView);
  }

  onMapViewClicked() {
    this.stateLocalCopy.View = this.activeView = ViewType.Map;
    this.updateDefaultMeasure();
    this.stateManager.updateStateFromIndicator(this.stateLocalCopy);
    this.setToolbarButtonsStatus(this.activeView);
  }

  onTableViewClicked() {
    this.stateLocalCopy.View = this.activeView = ViewType.Table;
    this.updateDefaultMeasure();
    this.stateManager.updateStateFromIndicator(this.stateLocalCopy);
    this.setToolbarButtonsStatus(this.activeView);
  }

  setToolbarButtonsStatus(view: string) {
    this.trendView.selected = view === this.viewType.Trend;
    this.snapshotView.selected = view === this.viewType.BarHorizontal;
    this.mapView.selected = view === this.viewType.Map;
    this.tableView.selected = view === this.viewType.Table;
  }

  // Enable/disable visualisation view buttons based on the groups currently selected.
  // If the combination of groups selected does not have the corresponding visualisations (trend, bar, map, graph) then those
  // visualisations will be disabled
  setToolbarButtonsDisabledStatus(state: IndicatorState) {
    this.trendView.disabled = true;
    this.snapshotView.disabled = true;
    this.mapView.disabled = true;
    this.tableView.disabled = true;

    const selectedGroups: string[] = state.Groups.filter((g) => g !== "Period");
    const presentations = state.Meta.locations.find(
      (location) => location.name === state.Location
    ).presentations;
    const presentationsWithSelectedGroups = !!selectedGroups.length
      ? presentations.filter((p) =>
          selectedGroups.every((selectedGroup) =>
            p.groups.includes(selectedGroup)
          )
        )
      : [presentations[0]];
    const viewObjects: StatsIndicatorLocationPresentationView[][] =
      presentationsWithSelectedGroups.map((p) => p.views);
    const allowedViews: StatsIndicatorLocationPresentationView[] = [].concat(
      ...viewObjects
    ); //flatten array
    allowedViews.forEach((v) => {
      if (v.name === ViewType.BarHorizontal) {
        this.snapshotView.disabled = false;
      } else if (v.name === ViewType.Map) {
        this.mapView.disabled = false;
      } else if (v.name === ViewType.Trend) {
        this.trendView.disabled = false;
      } else if (v.name === ViewType.Table) {
        this.tableView.disabled = false;
      }
    });
  }

  onConfidenceIntervalsClicked() {
    this.confidenceIntervalViewEnabled = true;
    this.confidenceIntervals.Toggle();
    this.stateLocalCopy.Confidence = this.confidenceIntervals.selected;
    this.stateManager.updateStateFromIndicator(this.stateLocalCopy);
  }

  private async loadShortUrl() {
    const urlPath = Util.GetCurrentUrlChildPath();
    const base64Path = Util.ToBase64(urlPath);
    const shortPath = await this._siteService.getOrSetShortPath(
      this.indicatorName,
      base64Path
    );
    this.citationData.url = RedirectService.CreateRedirectUrl(shortPath);
  }

  async openCitationDialog() {
    await this.loadShortUrl();
    this.citationDialog.open();
    this.sendGAClickEvent("Cite", "Cite");
  }

  copyToClipboard() {
    this.reference.nativeElement.select();
    document.execCommand("copy");
    this.citationDialog.close();
  }

  // TODO: disable functionality
  openEditSectionDialog() {
    if (this.myReportsEnabled) {
      this._myReportsIndicatorItemSelectionDialog.open(
        this.createReportSection()
      );
    } else {
      this.toggleAddReportBubble();
    }
  }

  createReportSection(): Section {
    return {
      id: this.reportService.generateId(this.stateLocalCopy),
      queryParam: this.stateManager
        .convertStateToHttpParams(this.stateLocalCopy)
        .toString(),
      title: this.getReportTitle(),
      graph: this.activeView !== this.viewType.Table,
      data: true,
      commentary: true,
      summary: true,
      sources: false,
      definitions: false,
      content: false,
      coding: false,
      methods: false,
      dimensions: false,
      references: false,
      view: this.activeView.toLocaleLowerCase(),
      indicator: this.stateLocalCopy.Meta.title,
      chart_title: this.stateLocalCopy.ChartTitle,
    };
  }

  getReportTitle() {
    if (
      this.stateLocalCopy &&
      this.stateLocalCopy.Meta &&
      this.stateLocalCopy.Meta.title
    ) {
      return `${this.stateLocalCopy.Meta.title} ${this.stateLocalCopy.ChartTitle}`.trim();
    } else {
      return "";
    }
  }

  download(docType: VisualisationDownloadFormat) {
    switch (docType) {
      case VisualisationDownloadFormat.CSV:
        this.downloadAsCSV();
        break;
      case VisualisationDownloadFormat.PNG:
      case VisualisationDownloadFormat.SVG:
        this.downloadGraphAs(docType);
        break;
    }
  }

  toggleDownloadBubble() {
    this.displayDownloadBubble = !this.displayDownloadBubble;
    this.sendGAClickEvent("Download", "Download");
  }

  toggleAddReportBubble() {
    this.displayAddReportBubble = !this.displayAddReportBubble;
    this.sendGAClickEvent("Addtoreport", "Add to report");
  }

  async downloadAsCSV() {
    const html = this.indicatorTableService.generateHtmlTable(
      this.stateLocalCopy,
      true
    );
    await this.loadShortUrl();
    let csv = this.csvService.exportTableToCSV(html);
    csv = `"${this.getReportTitle()}"\n${csv}`;
    csv += `\n"Source:"`;
    csv += `\n"${Util.convertToPlainText(this.source)}"`;
    if (this.releaseDate) csv += `\n"Date data updated: ${this.releaseDate}"`;
    csv += `\n"Citation:"`;
    csv += `\n"${Util.removeTags(this.citationData.source)} Available at: ${
      this.citationData.url
    } Accessed: ${this.citationData.date}"`;
    csv += `\n"Notes:"`;
    csv += `\n${this.getNotesSummaries()}`;

    this.csvService.downloadCSV(csv, "data.csv");
    this.displayDownloadBubble = false;
  }

  getNotesSummaries(): string {
    if (!this.accordions) {
      return "";
    }

    const noteSection = "Notes: What are the technical details of this data?";
    const noteAccordion = this.accordions.find(
      (accordion) => accordion.title == noteSection
    );
    if (!noteAccordion || !noteAccordion.children) {
      return "";
    }

    let summaries: string[] = [];
    noteAccordion.children.forEach((section) => {
      if (section.title && section.title != "References") {
        summaries.push(`"${section.title}"`);
        if (section.children) {
          section.children.forEach((content) => {
            if (content.recordType.toLowerCase() == "section") {
              if (content.children && content.children.length > 0) {
                content.children.forEach((child) => {
                  if (child.parentTitle.toLowerCase() == "summary") {
                    summaries.push(
                      `"${Util.convertToPlainText(child.articleText || "")}"`
                    );
                  }
                });
              }
            } else if (content.parentTitle.toLowerCase() == "summary") {
              summaries.push(
                `"${Util.convertToPlainText(content.articleText || "")}"`
              );
            }
          });
        }
      }
    });
    return summaries.join("\n");
  }

  downloadGraphAs(visualisationDownloadFormat: VisualisationDownloadFormat) {
    this.displayDownloadBubble = false;
    const graphSource = Util.removeTags(this.source);
    switch (this.activeView) {
      case ViewType.Trend:
        this.trendViewRef.exportAs(
          visualisationDownloadFormat,
          graphSource,
          "indicator-trend-view"
        );
        break;
      case ViewType.BarHorizontal:
        this.snapShotViewRef.exportAs(
          visualisationDownloadFormat,
          graphSource,
          "indicator-snapshot-view"
        );
        break;
      case ViewType.Map:
        this.mapViewRef.exportAs(
          visualisationDownloadFormat,
          "indicator-map-view"
        );
        break;
    }
  }

  showCiButton() {
    return (
      this.activeView !== this.viewType.Table &&
      this.activeView !== this.viewType.Map &&
      !!this.stateLocalCopy &&
      !!this.stateLocalCopy.ConfidenceIntervals[this.stateLocalCopy.Measure[0]]
    );
  }

  // This is temporary until API returns a flag to indicate if accordion should be expanded or not
  isCommentary(accordion: Accordion) {
    return (
      !!accordion &&
      !!accordion.title &&
      !!accordion.title.toLowerCase().includes("commentary")
    );
  }

  private sendGAClickEvent(eventName: string, eventLabel: string): void {
    this.googleAnalyticsService.pageEmitter(eventName);
    this.googleAnalyticsService.eventEmitter(
      "Click_" + eventName,
      "Indicator",
      "Click",
      eventLabel
    );
    this.appInsightsService.logPageView(eventName, eventName);
  }
}
